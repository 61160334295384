<template>
  <div>
    <v-row align="start" justify="center">
      <v-col cols="12" lg="8">
        <v-toolbar tile flat color="transparent">
          <v-toolbar-title>
            Video Codecs
          </v-toolbar-title>
          <v-spacer/>
          <v-btn fab small color="success" elevation="0" @click="Save"><v-icon>mdi-content-save</v-icon></v-btn>
        </v-toolbar>
        <!-- Main Codec Order -->
        <v-card outlined class="px-3 py-2 mb-3">
          <h3 id="videoCodecOrder" class="title">Main Codec Order</h3>
          <h4 class="font-weight-light mb-3">Drag and drop the codec order</h4>
          <draggable
              v-model="videoCodecOrder"
              group="videoCodecOrder"
              @start="drag=true"
              @end="drag=false"
          >
            <v-card
                class="mb-3 pb-0"
                v-for="(codec, index) in videoCodecOrder"
                :key="index"
                style="cursor: pointer"
                flat
            >
              <v-toolbar dense flat color="primary" dark>
                <v-toolbar-title>{{codec.name}}</v-toolbar-title>
                <v-spacer/>
                <span class="body-2">{{codec.codec}}</span>
              </v-toolbar>
            </v-card>
          </draggable>
        </v-card>
        <!-- 3G Codec Order -->
        <v-card outlined class="px-3 py-2 mb-3">
          <h3 id="videoCodecOrder3G" class="title">3G Codec Order</h3>
          <h4 class="font-weight-light mb-3">Drag and drop the codec order</h4>
          <draggable
              v-model="videoCodecOrder3G"
              group="videoCodecOrder3G"
              @start="drag=true"
              @end="drag=false"
          >
            <v-card
                class="mb-3 pb-0"
                v-for="(codec, index) in videoCodecOrder3G"
                :key="index"
                style="cursor: pointer"
                flat
            >
              <v-toolbar dense flat dark color="primary">
                <v-toolbar-title>{{codec.name}}</v-toolbar-title>
                <v-spacer/>
                <span class="body-2">{{codec.codec}}</span>
              </v-toolbar>
            </v-card>
          </draggable>
        </v-card>
        <!-- WebRTC Codec Order -->
        <v-card outlined class="px-3 py-2 mb-3">
          <h3 id="videoCodecOrderWebrtc" class="title">WebRTC Codec Order</h3>
          <h4 class="font-weight-light mb-3">Drag and drop the codec order</h4>
          <draggable
              v-model="videoCodecOrderWebrtc"
              group="videoCodecOrder3G"
              @start="drag=true"
              @end="drag=false"
          >
            <v-card
                class="mb-3 pb-0"
                v-for="(codec, index) in videoCodecOrderWebrtc"
                :key="index"
                style="cursor: pointer"
                flat
            >
              <v-toolbar dense flat dark color="primary">
                <v-toolbar-title>{{codec.name}}</v-toolbar-title>
                <v-spacer/>
                <span class="body-2">{{codec.codec}}</span>
              </v-toolbar>
            </v-card>
          </draggable>
        </v-card>

        <!-- Auto gen options -->
        <div
            v-for="(option, index) of options"
            :key="index"
        >
          <v-card outlined class="pa-3 mb-4">
            <h4 :id="option.option" class="text-capitalize font-weight-light pl-3 mb-1">{{option.option}}</h4>
            <v-text-field
                :placeholder="option.option"
                filled
                rounded
                dense
                hide-details
                class="mb-2"
                v-model="config[option.option]"
            />
            <div class="pl-3">
              <div class="font-weight-light">{{option.hint}}</div>
              <div><span class="blue-grey--text">Default:</span> {{option.default}}</div>
              <div><span class="blue-grey--text">Example:</span> {{option.example}}</div>
              <div v-if="option.validValues">
                <span class="blue-grey--text">Vaild Values:</span>
                <v-chip
                    v-for="value of option.validValues"
                    :key="value"
                    color="primary"
                    class="mx-1"
                    small
                >{{value}}</v-chip>
              </div>
            </div>
          </v-card>
        </div>
      </v-col>
      <v-col cols="4">
        <v-card outlined
                :style="currentScroll > 10 ? 'position: fixed; top: 260px; width: 260px':''"
                class="hidden-md-and-down"
        >
          <v-card-title>Options</v-card-title>
          <v-card-text>
            <!-- Codecs -->
            <!-- Main Codec Order -->
            <h3 class="body-1" style="cursor: pointer" @click="$vuetify.goTo('#videoCodecOrder')">
              Main Codec Order
            </h3>
            <!-- 3G Codec Order -->
            <h3 class="body-1" style="cursor: pointer" @click="$vuetify.goTo('#videoCodecOrder3G')">
              3G Codec Order
            </h3>
            <!-- WebRTC codec order -->
            <h3 class="body-1" style="cursor: pointer" @click="$vuetify.goTo('#videoCodecOrderWebrtc')">
              WebRTC Codec Order
            </h3>
            <v-divider class="my-2"/>
            <!-- Auto gen goTos -->
            <h3
                v-for="(item, index) of options"
                :key="index"
                @click="$vuetify.goTo(`#${item.option}`)"
                class="body-1"
                style="cursor: pointer"
            >{{index = index + 1}}: {{item.option}}</h3>
            <v-btn
                v-if="currentScroll > 10"
                color="success"
                style="margin-right: 60px"
                @click="Save"
                block
                class="my-2"
            >
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
            <v-btn
                v-if="currentScroll > 10"
                color="primary"
                @click="$vuetify.goTo(0)"
                block
            >
              <v-icon>mdi-arrow-up-bold</v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import draggable from "vuedraggable";
export default {
  name: "videoCodecs",
  components: {draggable},
  props: {
    domain:{
      type: String,
      required: true,
    }
  },
  data() {
    return{
      config: {},
      videoCodecOrder:[
        {codec: '108', name: 'VP8'},
        {codec: '99', name: 'H2264'}
      ],
      videoCodecOrder3G: [
        {codec: '108', name: 'VP8'},
        {codec: '99', name: 'H2264'}
      ],
      videoCodecOrderWebrtc: [
        {codec: '109', name: 'VP9'},
        {codec: '108', name: 'VP8'},
        {codec: '99', name: 'H2264'}
      ],
      currentScroll: 0,
      searchOptions: '',
      options: [
        {
          option: 'allowVideo',
          hint: 'Value 1 enables video calls in the application, 0 disables video calls.',
          default: '1',
          example: '1',
          validValues: ['0', '1'],
        },
        {
          option: 'videoMinKbpsWifi',
          hint: 'The values are in kilobits per second. Bandwidth range for video in case it’s being sent over wifi. ' +
              'The encoder is initially configured to produce bitrate in the middle of the given range and adjusts ' +
              'the bitrate adaptively towards either end of the range depending on the network conditions.',
          default: '64',
          example: '64',
          validValues: ['64-384'],
        },
        {
          option: 'videoMaxKbpsWifi',
          hint: 'Value 1 enables video calls in the application, 0 disables video calls.',
          default: '64',
          example: '64',
          validValues: ['64-384'],
        },
        {
          option: 'videoMinKbps3G',
          hint: 'The values are in kilobits per second. Bandwidth range for video in case it’s being sent over ' +
              'cellular network. See videoMinKbpsWifi and videoMaxKbpsWifi for more details.',
          default: '64',
          example: '64',
          validValues: ['64-192'],
        },
        {
          option: 'videoMaxKbps3G',
          hint: 'The values are in kilobits per second. Bandwidth range for video in case it’s being sent over ' +
              'cellular network. See videoMinKbpsWifi and videoMaxKbpsWifi for more details.',
          default: '192',
          example: '64',
          validValues: ['64-192'],
        },
        {
          option: 'videoDimsWifi',
          hint: 'Specifies video resolution to be sent over wifi',
          default: 'qcif',
          example: 'qcif',
          validValues: ['qcif', 'cif', 'vga', 'cif4', 'cif16', 'cif16', '720p', '1080p'],
        },
        {
          option: 'videoDims3G',
          hint: 'Specifies video resolution to be sent over cellular networks.',
          default: 'qcif',
          example: 'qcif',
          validValues: ['qcif', 'cif', 'vga', 'cif4', 'cif16', 'cif16', '720p', '1080p'],
        },
        {
          option: 'videoFPSWifi',
          hint: 'Frame rate in frames-per-second which the video encoder should use to encode video streams ' +
              'transferred over Wifi.',
          default: '',
          example: '15',
          validValues: ['15'],
        },
        {
          option: 'videoFPS3G',
          hint: 'Frame rate in frames-per-second which the video encoder should use to encode video streams transferred' +
              ' over cellular networks.',
          default: '',
          example: '15',
          validValues: ['15'],
        },
        {
          option: 'autoSendVideoWifi',
          hint: 'When set to empty string, the outgoing video stream is added to the call based on global ' +
              'app settings. Specifying 0 or 1 will override these settings for this particular account. ' +
              'When set to 1, video stream will be added into SDP when making general call (dialAction: “autoCall”)' +
              ' over wifi network or when answering incoming call without explicitly specifying media type ' +
              '(for example, by clicking on notification about incoming call)',
          default: '',
          example: '1',
          validValues: ['0', '1'],
        },
        {
          option: 'autoSendVideo3G',
          hint: 'Automatically add outgoing video stream into SDP when making call over cellular network.' +
              ' See autoSendVideoWifi for more details.',
          default: '',
          example: '1',
          validValues: ['0', '1'],
        },
        {
          option: 'autoReceiveVideoWifi',
          hint: 'Automatically allow incoming video stream when for calls over wifi. See autoSendVideoWifi for more details.',
          default: '',
          example: '1',
          validValues: ['0', '1'],
        },
        {
          option: 'autoReceiveVideo3G',
          hint: 'Automatically allow incoming video stream when for calls over cellular networks. ' +
              'See autoSendVideoWifi for more details.',
          default: '',
          example: '1',
          validValues: ['0', '1'],
        },
      ]
    }
  },
  beforeMount() {
    this.$axios.get('acrobits/get-settings', {params:{domain: this.domain}}).then(res=>{
      this.config = Object.assign({}, res.data)

      /*Sort main codec order*/
      let videoCodecOrder = this.config.videoCodecOrder.split(',')
      this.videoCodecOrder.sort((a,b)=>videoCodecOrder.indexOf(a.codec) - videoCodecOrder.indexOf(b.codec))

      /*Sort 3g codec order*/
      let videoCodecOrder3G = this.config.videoCodecOrder3G.split(',')
      this.videoCodecOrder3G.sort((a, b) => videoCodecOrder3G.indexOf(a.codec) - videoCodecOrder3G.indexOf(b.codec))

      /*Sort WebRTC codec order*/
      let videoCodecOrderWebrtc = this.config.videoCodecOrderWebrtc.split(',')
      this.videoCodecOrderWebrtc.sort((a, b) => videoCodecOrderWebrtc.indexOf(a.codec) - videoCodecOrderWebrtc.indexOf(b.codec))
    })
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.updateScroll)
  },
  methods:{
    updateScroll() {
      this.currentScroll = window.scrollY
    },
    Save() {

      this.config.videoCodecOrder = this.videoCodecOrder.map(codec => {
        return codec.codec
      }).join()

      this.config.videoCodecOrder3G = this.videoCodecOrder3G.map(codec =>{
        return codec.codec
      }).join()

      this.config.videoCodecOrderWebrtc = this.videoCodecOrderWebrtc.map(codec =>{
        return codec.codec
      }).join()

      this.$emit('save-settings', this.config)
    }
  }
}
</script>

<style scoped>

</style>